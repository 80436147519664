import type { BadgeVariant } from '@/components/ui/badge';
import { getCookie } from 'cookies-next';
import { DateTime } from 'luxon';
import type {
  IAvailableReport,
  IReportColumnOperation,
  IReportKind,
} from './types';

export const AVAILABLE_KINDS: IReportKind[] = [
  'base',
  'invoicing',
  'brf',
  'tenants',
  'objects',
  'rentincrease',
];

export const AVAILABLE_AGGREGATION_OPERATIONS: {
  v: IReportColumnOperation;
  d: string;
}[] = [
  { v: 'sum', d: 'Summera' },
  { v: 'count', d: 'Antal' },
  { v: 'max', d: 'Max' },
  { v: 'min', d: 'Min' },
  { v: 'mean', d: 'Genomsnitt' },
  { v: 'var', d: 'Varians' },
  { v: 'std', d: 'Standardavvikelse' },
];

export const AGGREGATION_OPERATION_VARIANTS: Record<
  IReportColumnOperation,
  BadgeVariant['variant']
> = {
  sum: 'green',
  count: 'purple',
  max: 'orange',
  min: 'yellow',
  mean: 'cyan',
  std: 'default',
  var: 'blue',
};

export const SHEET_TRANSLATIONS: Record<string, Record<string, string>> = {
  rent_roll: {
    rent_roll: 'Rent roll',
  },
  invoice_payments: {
    invoice_payments: 'Inbetalningar',
    invoice_payments_overpaid: 'Överbetalningar',
    invoice_payments_unmatched: 'Omatchade',
  },
  tenants: {
    tenants: 'Hyresgäster',
  },
  customer_balance: {
    customer_balance: 'Kundsaldo',
    customer_balance_analysis: 'Analys',
  },
  prepaid: {
    prepaid_revenue: 'Förbetalade intäkter',
    prepaid_revenue_analysis: 'Analys',
  },
  periodised_sales: {
    periodised_sales: 'Periodiserad försäljning',
  },
  invoices: {
    non_rental_invoices: 'Fakturor',
    rental_invoices: 'Avier',
  },
  verifications: {
    verifications: 'Verifikat',
  },
  sales: {
    sales: 'Försäljning',
  },
  prognosis: {
    prognosis: 'Prognos',
  },
  vacancies: {
    vacancies: 'Vakanser',
  },
  moving: {
    moving: 'In & utflytts-rapport',
  },
};

export const AVAILABLE_REPORTS: IAvailableReport[] = [
  {
    internalId: 'rentrollexpanded',
    externalName: 'rent_roll',
    kind: 'base',
    verboseName: 'Rent roll',
    organizationFilterTargetKeys: [
      // keys to add org filter on for all sheets they can be found in
      'contracts.basecontract.organization',
    ],
    description:
      'Samtliga aktiva avtal vid ett givet datum. Innehåller information kring hyresgäster, avtal och objekt.',
    config: {
      report_type: 'rent_roll',
      sheet_configurations: {
        rent_roll: {
          group_by: [],
          exclude_columns: [
            'contracts.basecontract.organization',
            'contracts.basecontract_revenue.organization',
            'contracts.basecontract_with_space_revenue.organization',
            'accounting.automaticcontractinvoicingsetting.organization',
            'contracts.basecontract_main_contract.organization',
            'organization.company_invoicing.organization',
            'organization.company_landlord.organization',
            'objects.basespace.organization',
            'objects.basespace_revenue.organization',
            'objects.basestructure.organization',
            'objects.realestate.organization',
            'objects.region.organization',
            'objects.community.organization',
            'objects.segment.organization',
            'accounts.tenant.organization',
            'accounts.tenant_bi_tenant.organization',
            'accounting.autogiropayerconnection.organization',
            'revenue.indexationsettingusage.organization',
            'revenue.indexationsetting.organization',
            'revenue.indexationtable.organization',
            'organization.company_invoicing_tags.organization',
            'objects.region_tags.organization',
            'objects.segment_tags.organization',
            'objects.realestate_tags.organization',
            'objects.basestructure_tags.organization',
            'objects.basespace_tags.organization',
            'accounts.tenant_tags.organization',
            'contracts.basecontract_tags.organization',
            'accounting.automaticcontractinvoicingsetting.admin_fee_ui',
            'accounting.automaticcontractinvoicingsetting.admin_fee_postal',
            'accounting.automaticcontractinvoicingsetting.admin_fee_e_inbox',
            'accounting.automaticcontractinvoicingsetting.admin_fee_email',
            'accounting.automaticcontractinvoicingsetting.admin_fee_sms',
            'accounting.automaticcontractinvoicingsetting.name',
            'accounting.automaticcontractinvoicingsetting.source_identifier',
            'objects.realestate.source_identifier',
            'contracts.basecontract.custom_dimension_value',
          ],
          filters: [],
          aggregation_operations: [],
          column_order: [
            'organization.company.name',
            'organization.company.org_no',
            'organization.company.sni_number',
            'organization.company.visiting_address',
            'organization.company.postal_address',
            'objects.region.source_identifier',
            'objects.region.name',
            'objects.community.source_identifier',
            'objects.community.name',
            'objects.community.main_address',
            'objects.segment.source_identifier',
            'objects.segment.name',
            'objects.basestructure.address',
            'objects.realestate.id',
            'objects.realestate.name',
            'objects.realestate.popular_name',
            'objects.realestate.financial_responsibility_from',
            'objects.realestate.financial_responsibility_to',
            'objects.realestate.main_address',
            'objects.basestructure.source_identifier',
            'objects.basestructure.id',
            'objects.basestructure.name',
            'objects.basestructure.popular_name',
            'objects.basespace.source_identifier',
            'objects.basespace.id',
            'objects.basespace.category',
            'objects.basespace.popular_name',
            'objects.basespace.usable_area',
            'objects.basespace.bi_area',
            'objects.basespace.total_area',
            'objects.basespace.address',
            'accounts.tenant.source_identifier',
            'accounts.tenant.id',
            'accounts.tenant.communication_name',
            'accounts.tenant.ssn',
            'accounts.tenant.phone_number',
            'accounts.tenant.email',
            'accounts.tenant.current_registered_address',
            'accounts.tenant.realestates',
            'objects.basespace.landlord_custom_id',
            'contracts.basecontract.id',
            'contracts.basecontract.id_number',
            'contracts.basecontract.source_identifier',
            'contracts.basecontract.status',
            'contracts.basecontract.signed_time',
            'contracts.basecontract.start_date',
            'contracts.basecontract.initial_end_date',
            'contracts.basecontract.renewed_to',
            'contracts.basecontract.last_owner_notification_date',
            'contracts.basecontract.renew_interval_months',
            'contracts.basecontract.cancellation_time',
            'contracts.basecontract.cancellation_signed_time',
            'contracts.basecontract.lifetime_end_date',
            'contracts.basecontract.cancellation_reason',
            'contracts.basecontract_tags.referenced_by_tags',
            'contracts.basecontract.invoicing_period',
            'contracts.basecontract.invoice_month_interval',
            'contracts.basecontract_revenue.private_base_rent',
            'contracts.basecontract_revenue.private_yearly_base_rent',
            'contracts.basecontract_revenue.private_non_base_rent',
            'contracts.basecontract_revenue.private_yearly_non_base_rent',
            'contracts.basecontract_revenue.private_invoicing_period_value',
          ],
        },
      },
      report_filters: {},
    },
  },
  {
    internalId: 'baseinvoicepayments',
    externalName: 'invoice_payments',
    kind: 'invoicing',
    verboseName: 'Inbetalningar',
    organizationFilterTargetKeys: ['organization.company.organization'],
    description: 'Inbetalningar under ett givet intervall',
    config: {
      report_filters: {
        period_start: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
        period_end: DateTime.now().endOf('month').toFormat('yyyy-MM-dd'),
      },
      report_type: 'invoice_payments',
      sheet_configurations: {
        invoice_payments: {
          group_by: [],
          exclude_columns: [
            'organization.company.organization',
            'accounting.invoicepayment.organization',
            'accounting.invoice.organization',
            'organization.company.source_identifier',
            'organization.company.id',
            'organization.company.org_no',
            'organization.company.sni_number',
            'accounting.invoice.main_period_end',
            'accounting.invoice.main_period_start',
            'accounting.invoice.ocr_number',
            'accounting.invoice.invoice_number',
            'accounting.invoice.due_daye_days',
            'accounting.invoice.due_date',
            'accounting.invoice.invoice_date',
            'accounting.invoice.id',
            'accounting.invoice.source_identifier',
            'accounting.invoicepayment.organization_user',
            'accounting.invoicepayment.tenant',
            'accounting.invoicepayment.registration_date',
            'accounting.invoicepayment.matched_date',
            'accounting.invoicepayment.id',
            'accounting.invoicepayment.source_identifier',
            'organization.company.invoice_identifier',
            'accounting.invoicepayment.additional_information',
          ],
          filters: [],
          aggregation_operations: [],
          column_order: [
            'organization.company.name',
            'accounting.invoicepayment.distributed_ocr_number',
            'accounting.invoicepayment.sender_name',
            'accounting.invoicepayment.value',
            'accounting.invoicepayment.payment_date',
            'accounting.invoicepayment.ocr_number',
            'organization.company.visiting_address',
            'organization.company.postal_address',
            'accounting.invoicepayment.payment_method',
            'accounting.invoicepayment.status',
          ],
        },
        invoice_payments_overpaid: {
          group_by: [],
          exclude_columns: [
            'organization.company.organization',
            'accounting.invoicepayment.organization',
            'accounting.invoice.organization',
            'accounting.invoice.due_daye_days',
            'accounting.invoice.due_date',
            'accounting.invoice.main_period_end',
            'accounting.invoice.main_period_start',
            'accounting.invoice.ocr_number',
            'accounting.invoice.invoice_number',
            'accounting.invoice.invoice_date',
            'accounting.invoice.id',
            'accounting.invoice.source_identifier',
            'accounting.invoicepayment.organization_user',
            'accounting.invoicepayment.tenant',
            'accounting.invoicepayment.additional_information',
            'accounting.invoicepayment.id',
            'accounting.invoicepayment.source_identifier',
            'organization.company.invoice_identifier',
            'organization.company.sni_number',
            'organization.company.org_no',
            'organization.company.id',
            'organization.company.source_identifier',
            'accounting.invoicepayment.matched_date',
            'accounting.invoicepayment.registration_date',
          ],
          filters: [],
          aggregation_operations: [],
          column_order: [
            'organization.company.name',
            'accounting.invoicepayment.distributed_ocr_number',
            'accounting.invoicepayment.sender_name',
            'accounting.invoicepayment.value',
            'accounting.invoicepayment.payment_date',
            'accounting.invoicepayment.ocr_number',
            'organization.company.visiting_address',
            'organization.company.postal_address',
            'accounting.invoicepayment.payment_method',
            'accounting.invoicepayment.status',
          ],
        },
        invoice_payments_unmatched: {
          group_by: [],
          exclude_columns: [
            'organization.company.organization',
            'accounting.invoicepayment.organization',
            'accounting.invoice.organization',
            'organization.company.source_identifier',
            'organization.company.id',
            'organization.company.org_no',
            'organization.company.sni_number',
            'organization.company.invoice_identifier',
            'accounting.invoice.ocr_number',
            'accounting.invoice.invoice_number',
            'accounting.invoice.due_daye_days',
            'accounting.invoice.due_date',
            'accounting.invoice.invoice_date',
            'accounting.invoice.id',
            'accounting.invoice.source_identifier',
            'accounting.invoice.main_period_end',
            'accounting.invoice.main_period_start',
            'accounting.invoicepayment.organization_user',
            'accounting.invoicepayment.tenant',
            'accounting.invoicepayment.additional_information',
            'accounting.invoicepayment.matched_date',
            'accounting.invoicepayment.registration_date',
            'accounting.invoicepayment.source_identifier',
            'accounting.invoicepayment.id',
          ],
          filters: [],
          aggregation_operations: [],
          column_order: [
            'organization.company.name',
            'accounting.invoicepayment.distributed_ocr_number',
            'accounting.invoicepayment.sender_name',
            'accounting.invoicepayment.value',
            'accounting.invoicepayment.payment_date',
            'accounting.invoicepayment.ocr_number',
            'organization.company.visiting_address',
            'organization.company.postal_address',
            'accounting.invoicepayment.payment_method',
            'accounting.invoicepayment.status',
          ],
        },
      },
    },
  },
  {
    internalId: 'basetenants',
    externalName: 'tenants',
    kind: 'tenants',
    verboseName: 'Kontaktlista hyresgäster',
    organizationFilterTargetKeys: ['accounts.tenant.organization'],
    description: 'Kontaktinformation till hyresgäster',
    config: {
      report_type: 'tenants',
      sheet_configurations: {
        tenants: {
          group_by: [],
          exclude_columns: [
            'accounts.tenant.organization',
            'accounts.tenant.source_identifier',
            'accounts.tenant.id',
          ],
          filters: [],
          aggregation_operations: [],
          column_order: [
            'accounts.tenant.communication_name',
            'accounts.tenant.ssn',
            'accounts.tenant.phone_number',
            'accounts.tenant.email',
            'accounts.tenant.current_registered_address',
            'accounts.tenant.realestates',
          ],
        },
      },
    },
  },
  {
    internalId: 'basecustomerbalance',
    externalName: 'customer_balance',
    kind: 'invoicing',
    verboseName: 'Reskontra',
    organizationFilterTargetKeys: ['organization.company.organization'],
    description:
      'Reskontra för avier och fakturor per ett specifikt datum. Varje rad visar fordran per avi/faktura. ',
    config: {
      report_filters: {
        for_date: DateTime.now().toFormat('yyyy-MM-dd'),
      },
      report_type: 'customer_balance',
      sheet_configurations: {
        customer_balance: {
          group_by: [],
          exclude_columns: [
            'organization.company.organization',
            'accounting.invoice.organization',
            'organization.company.id',
            'organization.company.source_identifier',
            'organization.company.org_no',
            'organization.company.sni_number',
            'organization.company.invoice_identifier',
            'accounting.invoice.id',
            'accounting.invoice.main_period_start',
            'accounting.invoice.main_period_end',
            'accounting.invoice.net_vat_25',
            'accounting.invoice.net_vat_12',
            'accounting.invoice.net_vat_6',
            'accounting.invoice.vat_25',
            'accounting.invoice.vat_12',
            'accounting.invoice.vat_6',
            'accounting.invoice.total_vat',
            'accounting.invoice.total_non_vat_base',
            'accounting.invoice.total_vat_base',
            'accounting.invoice.source_identifier',
          ],
          filters: [],
          aggregation_operations: [],
          column_order: [
            'organization.company.name',
            'accounting.invoice.invoice_number',
            'accounting.invoice.ocr_number',
            'accounting.invoice.recipients',
            'accounting.invoice.invoice_date',
            'accounting.invoice.due_date',
            'accounting.invoice.due_daye_days',
            'accounting.invoice.total_to_value',
            'accounting.invoice.left_to_pay',
          ],
        },
        customer_balance_analysis: {
          group_by: [],
          exclude_columns: [
            'organization.company.organization',
            'accounting.invoice.organization',
            'accounting.invoice_analysis.organization',
            'organization.company.source_identifier',
            'organization.company.id',
            'organization.company.org_no',
            'organization.company.sni_number',
            'organization.company.invoice_identifier',
            'accounting.invoice.source_identifier',
            'accounting.invoice.id',
            'accounting.invoice.net_vat_25',
            'accounting.invoice.net_vat_12',
            'accounting.invoice.net_vat_6',
            'accounting.invoice.vat_25',
            'accounting.invoice.vat_12',
            'accounting.invoice.vat_6',
            'accounting.invoice.total_vat',
            'accounting.invoice.total_non_vat_base',
            'accounting.invoice.total_vat_base',
            'accounting.invoice.total_to_value',
            'accounting.invoice.main_period_end',
            'accounting.invoice.main_period_start',
            'accounting.invoice.due_daye_days',
            'accounting.invoice.due_date',
            'accounting.invoice.invoice_date',
          ],
          filters: [],
          aggregation_operations: [],
          column_order: [
            'organization.company.name',
            'accounting.invoice.invoice_number',
            'accounting.invoice.ocr_number',
            'accounting.invoice.recipients',
            'accounting.invoice.left_to_pay',
            'accounting.invoice_analysis.bookkept_receivable',
            'accounting.invoice_analysis.bookkept_receivable_difference',
          ],
        },
      },
    },
  },
  {
    internalId: 'baseprepaid',
    externalName: 'prepaid',
    kind: 'invoicing',
    verboseName: 'Förutbetalade intäkter',
    organizationFilterTargetKeys: ['organization.company.organization'],
    description:
      'Avstämning av förutbetalade intäkter, tas ut för ett specifikt datum. ',
    config: {
      report_type: 'prepaid',
      report_filters: {
        for_date: DateTime.now().toFormat('yyyy-MM-dd'),
      },
      sheet_configurations: {
        prepaid_revenue: {
          group_by: [],
          exclude_columns: [
            'organization.company.organization',
            'accounting.invoice.organization',
            'accounting.invoicepayment.organization',
            'organization.company.source_identifier',
            'organization.company.id',
            'organization.company.org_no',
            'organization.company.sni_number',
            'organization.company.invoice_identifier',
            'organization.company.visiting_address',
            'organization.company.postal_address',
            'accounting.invoice.source_identifier',
            'accounting.invoice.id',
            'accounting.invoice.invoice_date',
            'accounting.invoice.due_date',
            'accounting.invoice.due_daye_days',
            'accounting.invoicepayment.organization_user',
            'accounting.invoicepayment.additional_information',
            'accounting.invoicepayment.id',
            'accounting.invoicepayment.source_identifier',
            'accounting.invoice.main_period_end',
            'accounting.invoice.main_period_start',
            'accounting.invoicepayment.status',
            'accounting.invoicepayment.distributed_ocr_number',
            'accounting.invoicepayment.matched_date',
            'accounting.invoicepayment.sender_name',
            'accounting.invoicepayment.ocr_number',
          ],
          filters: [],
          aggregation_operations: [],
          column_order: [
            'organization.company.name',
            'accounting.invoice.invoice_number',
            'accounting.invoice.ocr_number',
            'accounting.invoicepayment.tenant',
            'accounting.invoicepayment.value',
            'accounting.invoicepayment.registration_date',
            'accounting.invoicepayment.payment_date',
            'accounting.invoicepayment.payment_method',
          ],
        },
        prepaid_revenue_analysis: {
          group_by: [],
          exclude_columns: [
            'organization.company.organization',
            'accounting.invoice.organization',
            'accounting.invoicepayment.organization',
            'accounting.invoicepayment_analysis.organization',
            'organization.company.source_identifier',
            'organization.company.id',
            'organization.company.org_no',
            'organization.company.sni_number',
            'organization.company.invoice_identifier',
            'organization.company.visiting_address',
            'organization.company.postal_address',
            'accounting.invoice.source_identifier',
            'accounting.invoice.id',
            'accounting.invoice.due_date',
            'accounting.invoice.due_daye_days',
            'accounting.invoice.main_period_start',
            'accounting.invoice.main_period_end',
            'accounting.invoicepayment.source_identifier',
            'accounting.invoicepayment.id',
            'accounting.invoice.invoice_date',
            'accounting.invoicepayment.payment_date',
            'accounting.invoicepayment.matched_date',
            'accounting.invoicepayment.registration_date',
            'accounting.invoicepayment.ocr_number',
            'accounting.invoicepayment.sender_name',
            'accounting.invoicepayment.additional_information',
            'accounting.invoicepayment.status',
            'accounting.invoicepayment.organization_user',
            'accounting.invoicepayment.distributed_ocr_number',
            'accounting.invoicepayment.payment_method',
          ],
          filters: [],
          aggregation_operations: [],
          column_order: [
            'organization.company.name',
            'accounting.invoice.invoice_number',
            'accounting.invoice.ocr_number',
            'accounting.invoicepayment.tenant',
            'accounting.invoicepayment.value',
            'accounting.invoicepayment_analysis.bookkept_receivable',
            'accounting.invoicepayment_analysis.bookkept_receivable_difference',
          ],
        },
      },
    },
  },
  {
    internalId: 'baseperiodisedsales',
    externalName: 'periodised_sales',
    kind: 'invoicing',
    verboseName: 'Periodiserad försäljning',
    organizationFilterTargetKeys: ['organization.company.organization'],
    description:
      'Rapporten visar en sammanställning av den debitering som har aviserats eller fakturerats för en viss period. Är även möjligt att inkludera ännu ej attesterade avier för kontroll dessa avier saknar avi/fakturanummer samt OCR. ',
    config: {
      report_type: 'periodised_sales',
      report_filters: {
        for_date: DateTime.now().toFormat('yyyy-MM-dd'),
      },
      sheet_configurations: {
        periodised_sales: {
          group_by: [],
          exclude_columns: [
            'organization.company.organization',
            'accounting.invoice.organization',
            'accounting.invoicerow.organization',
            'accounting.article.organization',
            'organization.company.source_identifier',
            'organization.company.id',
            'organization.company.sni_number',
            'organization.company.invoice_identifier',
            'organization.company.visiting_address',
            'organization.company.postal_address',
            'accounting.invoice.source_identifier',
            'accounting.invoice.id',
            'accounting.article.source_identifier',
            'accounting.invoicerow.total_value',
            'accounting.invoicerow.periodised_net',
            'accounting.invoicerow.period_length',
            'accounting.invoicerow.bookkeeping_period_end',
            'accounting.invoicerow.bookkeeping_period_start',
            'accounting.invoicerow.id',
            'accounting.invoicerow.source_identifier',
            'accounting.invoice.contract_id_number',
            'accounting.invoice.main_period_end',
            'accounting.invoice.main_period_start',
            'accounting.invoice.due_daye_days',
            'accounting.invoice.due_date',
          ],
          filters: [],
          aggregation_operations: [],
          column_order: [
            'organization.company.name',
            'organization.company.org_no',
            'accounting.invoice.recipients',
            'accounting.invoice.ocr_number',
            'accounting.invoice.invoice_number',
            'accounting.invoice.invoice_date',
            'accounting.invoicerow.period_start',
            'accounting.invoicerow.period_end',
            'accounting.invoicerow.remaining_periods',
            'accounting.invoicerow.last_periodisation_date',
            'accounting.invoicerow.total_net',
            'accounting.invoicerow.month_net',
            'accounting.invoicerow.revenue_net',
            'accounting.article.category',
            'accounting.article.name',
            'accounting.invoicerow.title',
            'accounting.invoicerow.used_article_account',
            'accounting.invoicerow.dimensions',
            'accounting.invoicerow.quantity',
            'accounting.invoicerow.unit',
            'accounting.invoicerow.vat',
            'accounting.invoicerow.total_vat_value',
          ],
        },
      },
    },
  },
  {
    internalId: 'basesales',
    externalName: 'sales',
    kind: 'invoicing',
    organizationFilterTargetKeys: ['organization.company.organization'],
    verboseName: 'Försäljning',
    description:
      'Rapporten visar en sammanställning av den debitering som har aviserats eller fakturerats för en viss period.',
    config: {
      report_type: 'sales',
      report_filters: {
        period_start: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
        period_end: DateTime.now().endOf('month').toFormat('yyyy-MM-dd'),
        rental_invoices_filter: null,
        include_unattested_invoices: false,
      },
      sheet_configurations: {
        sales: {
          group_by: [],
          exclude_columns: [
            'organization.company.organization',
            'accounting.invoice.organization',
            'accounting.invoicerow.organization',
            'accounting.article.organization',
            'organization.company.source_identifier',
            'organization.company.id',
            'organization.company.sni_number',
            'organization.company.invoice_identifier',
            'organization.company.visiting_address',
            'organization.company.postal_address',
            'accounting.invoice.source_identifier',
            'accounting.invoice.id',
            'accounting.article.source_identifier',
            'accounting.invoicerow.total_value',
            'accounting.invoicerow.periodised_net',
            'accounting.invoicerow.period_length',
            'accounting.invoicerow.bookkeeping_period_end',
            'accounting.invoicerow.bookkeeping_period_start',
            'accounting.invoicerow.id',
            'accounting.invoicerow.source_identifier',
            'accounting.invoice.contract_id_number',
            'accounting.invoice.main_period_end',
            'accounting.invoice.main_period_start',
            'accounting.invoice.due_daye_days',
            'accounting.invoice.due_date',
          ],
          filters: [],
          aggregation_operations: [],
          column_order: [
            'organization.company.name',
            'organization.company.org_no',
            'accounting.invoice.recipients',
            'accounting.invoice.ocr_number',
            'accounting.invoice.invoice_number',
            'accounting.invoice.invoice_date',
            'accounting.invoicerow.period_start',
            'accounting.invoicerow.period_end',
            'accounting.invoicerow.remaining_periods',
            'accounting.invoicerow.last_periodisation_date',
            'accounting.invoicerow.total_net',
            'accounting.invoicerow.month_net',
            'accounting.invoicerow.revenue_net',
            'accounting.article.category',
            'accounting.article.name',
            'accounting.invoicerow.title',
            'accounting.invoicerow.used_article_account',
            'accounting.invoicerow.dimensions',
            'accounting.invoicerow.quantity',
            'accounting.invoicerow.unit',
            'accounting.invoicerow.vat',
            'accounting.invoicerow.total_vat_value',
          ],
        },
      },
    },
  },
  {
    internalId: 'baseverifications',
    externalName: 'verifications',
    kind: 'invoicing',
    verboseName: 'Bokföringsjournal',
    organizationFilterTargetKeys: ['organization.company.organization'],
    description:
      'Rapporten visar varje enskild bokföringstransaktion som har skett under ett givet intervall för ett eller flera konton. Används vanligtvis för att stämma av mot bokföringssystemet. Varje rad visar en specifik kontering.',
    config: {
      report_type: 'verifications',
      report_filters: {
        period_start: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
        period_end: DateTime.now().endOf('month').toFormat('yyyy-MM-dd'),
      },
      sheet_configurations: {
        verifications: {
          group_by: [],
          exclude_columns: [
            'organization.company.organization',
            'accounting.invoice.organization',
            'accounting.invoicepayment.organization',
            'accounting.transaction.organization',
            'accounting.verification.organization',
            'organization.company.source_identifier',
            'organization.company.id',
            'organization.company.org_no',
            'organization.company.sni_number',
            'organization.company.invoice_identifier',
            'organization.company.visiting_address',
            'organization.company.postal_address',
            'accounting.invoice.source_identifier',
            'accounting.invoice.id',
            'accounting.transaction.id',
            'accounting.transaction.source_identifier',
            'accounting.invoice.main_period_start',
            'accounting.invoice.main_period_end',
            'accounting.invoice.invoice_date',
            'accounting.invoice.due_date',
            'accounting.invoice.due_daye_days',
            'accounting.verification.source_identifier',
          ],
          filters: [],
          aggregation_operations: [],
          column_order: [
            'organization.company.name',
            'accounting.transaction.account',
            'accounting.invoice.invoice_number',
            'accounting.invoice.ocr_number',
            'accounting.verification.bookkeeping_date',
            'accounting.verification.description',
            'accounting.transaction.debit_value',
            'accounting.transaction.credit_value',
            'accounting.transaction.dimensions',
          ],
        },
      },
    },
  },
  {
    internalId: 'baseinvoices',
    externalName: 'invoices',
    kind: 'invoicing',
    verboseName: 'Avier & Fakturor',
    organizationFilterTargetKeys: ['organization.company.organization'],
    description:
      'Rapporten visar övergripande information för de avier och fakturor som har faktura- eller avidatum under perioden för rapporten. Varje rad i rapporten visar en faktura eller avi.',
    config: {
      report_type: 'invoices',
      report_filters: {
        rental_invoices_filter: null,
        period_start: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
        period_end: DateTime.now().endOf('month').toFormat('yyyy-MM-dd'),
      },
      sheet_configurations: {
        non_rental_invoices: {
          group_by: [],
          exclude_columns: [
            'organization.company.organization',
            'accounting.invoice.organization',
            'organization.company.source_identifier',
            'organization.company.id',
            'organization.company.org_no',
            'organization.company.sni_number',
            'organization.company.invoice_identifier',
            'accounting.invoice.source_identifier',
            'accounting.invoice.id',
            'accounting.invoice.due_daye_days',
            'accounting.invoice.main_period_start',
            'accounting.invoice.main_period_end',
            'accounting.invoice.left_to_pay',
          ],
          filters: [],
          aggregation_operations: [],
          column_order: [
            'organization.company.name',
            'accounting.invoice.invoice_number',
            'accounting.invoice.ocr_number',
            'accounting.invoice.recipients',
            'accounting.invoice.invoice_date',
            'accounting.invoice.due_date',
            'accounting.invoice.total_to_value',
            'accounting.invoice.total_vat_base',
            'accounting.invoice.total_non_vat_base',
            'accounting.invoice.total_vat',
            'accounting.invoice.vat_6',
            'accounting.invoice.vat_12',
            'accounting.invoice.vat_25',
            'accounting.invoice.net_vat_6',
            'accounting.invoice.net_vat_12',
            'accounting.invoice.net_vat_25',
          ],
        },
        rental_invoices: {
          group_by: [],
          exclude_columns: [
            'organization.company.organization',
            'accounting.invoice.organization',
            'organization.company.source_identifier',
            'organization.company.sni_number',
            'organization.company.org_no',
            'organization.company.invoice_identifier',
            'accounting.invoice.source_identifier',
            'accounting.invoice.id',
            'accounting.invoice.total_non_vat_base',
            'accounting.invoice.total_vat_base',
            'accounting.invoice.left_to_pay',
            'organization.company.id',
          ],
          filters: [],
          aggregation_operations: [],
          column_order: [
            'organization.company.name',
            'accounting.invoice.invoice_number',
            'accounting.invoice.ocr_number',
            'accounting.invoice.recipients',
            'accounting.invoice.invoice_date',
            'accounting.invoice.due_date',
            'accounting.invoice.due_daye_days',
            'accounting.invoice.main_period_start',
            'accounting.invoice.main_period_end',
            'accounting.invoice.total_to_value',
            'accounting.invoice.total_vat',
            'accounting.invoice.vat_6',
            'accounting.invoice.vat_12',
            'accounting.invoice.vat_25',
            'accounting.invoice.net_vat_6',
            'accounting.invoice.net_vat_12',
            'accounting.invoice.net_vat_25',
          ],
        },
      },
    },
  },
  {
    internalId: 'baseprognosis',
    externalName: 'prognosis',
    kind: 'invoicing',
    verboseName: 'Aviseringsprognos',
    organizationFilterTargetKeys: ['organization.company.organization'],
    description:
      'Rapport över de avier som prognostiseras att ställas ut under en given period. Respektive rad i rapporten visar en debiteringsrad på en avi. Rapporten skall användas för att analysera kommande avisering och är ej lämpad för analys av historiska händelser.',
    config: {
      report_type: 'prognosis',
      report_filters: {
        organization_id: getCookie('organization_id') as string,
        company_ids: null,
        realestate_ids: null,
        building_ids: null,
        outdoor_area_ids: null,
        parking_lot_ids: null,
        period_start: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
        period_end: DateTime.now()
          .plus({ month: 1 })
          .startOf('month')
          .toFormat('yyyy-MM-dd'),
        include_corporate_tenants: true,
        include_private_tenants: true,
        ignore_co_invoicing_settings: false,
        ignore_automatic_creation_paused: false,
        invoiceable_types: [
          'contracts.apartmentcontract',
          'contracts.industrialpremisescontract',
          'contracts.parkingspotcontract',
          'contracts.outdoorsectioncontract',
          'contracts.othercontract',
          'contracts.blockcontract',
          'brf.brfapartmentinvoiceconfiguration',
        ],
      },
      sheet_configurations: {
        prognosis: {
          group_by: [],
          exclude_columns: [
            'organization.company.organization',
            'objects.realestate.organization',
            'contracts.basecontract.organization',
            'objects.basespace.organization',
            'accounting.invoicerow.organization',
            'accounting.invoice.organization',
            'accounting.invoicerecipient.organization',
            'organization.company.source_identifier',
            'organization.company.sni_number',
            'objects.realestate.source_identifier',
            'contracts.basecontract.id',
            'contracts.basecontract.start_date',
            'contracts.basecontract.cancellation_time',
            'contracts.basecontract.last_owner_notification_date',
            'contracts.basecontract.renew_interval_months',
            'contracts.basecontract.renewed_to',
            'contracts.basecontract.cancellation_signed_time',
            'contracts.basecontract.initial_end_date',
            'contracts.basecontract.cancellation_reason',
            'contracts.basecontract.lifetime_end_date',
            'contracts.basecontract.signed_time',
            'contracts.basecontract.custom_dimension_value',
            'objects.basespace.total_area',
            'objects.basespace.bi_area',
            'objects.basespace.usable_area',
            'accounting.invoicerow.id',
            'accounting.invoicerow.source_identifier',
            'objects.basespace.popular_name',
            'objects.basespace.id',
            'objects.basespace.landlord_custom_id',
            'objects.realestate.popular_name',
            'organization.company.invoice_identifier',
            'contracts.basecontract.status',
            'accounting.invoicerow.bookkeeping_period_start',
            'accounting.invoicerow.bookkeeping_period_end',
            'accounting.invoicerow.remaining_periods',
            'accounting.invoicerow.quantity',
            'accounting.invoicerow.unit',
            'accounting.invoicerow.revenue_net',
            'accounting.invoicerow.total_value',
            'accounting.invoicerow.periodised_net',
            'accounting.invoice.id',
            'accounting.invoice.source_identifier',
            'accounting.invoice.due_date',
            'accounting.invoice.due_daye_days',
            'accounting.invoicerow.month_net',
            'accounting.invoice.ocr_number',
            'accounting.invoicerecipient.source_identifier',
            'accounting.invoice.invoice_number',
            'accounting.invoicerow.total_net',
          ],
          filters: [],
          aggregation_operations: [],
          column_order: [
            'organization.company.name',
            'organization.company.id',
            'organization.company.org_no',
            'objects.realestate.id',
            'objects.realestate.name',
            'accounting.invoicerecipient.calculated_name',
            'contracts.basecontract.id_number',
            'contracts.basecontract.source_identifier',
            'objects.basespace.source_identifier',
            'objects.basespace.category',
            'contracts.basecontract.invoicing_period',
            'contracts.basecontract.invoice_month_interval',
            'accounting.invoice.main_period_start',
            'accounting.invoice.main_period_end',
            'accounting.invoicerow.period_length',
            'accounting.invoice.invoice_date',
            'accounting.invoicerow.period_start',
            'accounting.invoicerow.period_end',
            'accounting.invoicerow.last_periodisation_date',
            'accounting.invoicerow.vat',
            'accounting.invoicerow.total_vat_value',
            'accounting.invoicerow.title',
            'accounting.invoicerow.used_article_account',
            'accounting.invoicerow.dimensions',
          ],
        },
      },
    },
  },
  {
    internalId: 'vacancies',
    externalName: 'vacancies',
    kind: 'objects',
    verboseName: 'Vakanser',
    organizationFilterTargetKeys: ['objects.basespace.organization'],
    description: 'Rapporten visar information om vakanser för ett givet datum.',
    config: {
      report_filters: {
        for_date: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
        company_ids: null,
        object_types: [
          'objects.apartment',
          'objects.industrialpremises',
          'objects.parkingspot',
          'objects.outdoorsection',
        ],
        realestate_ids: null,
      },
      report_type: 'vacancies',
      sheet_configurations: {
        vacancies: {
          group_by: [],
          exclude_columns: [
            'accounting.vacancyinvoice.organization',
            'objects.basespace.organization',
            'objects.realestate.organization',
            'objects.basespace_revenue.organization',
            'objects.basespace_tags.organization',
            'objects.basestructure.organization',
            'organization.company.organization',
          ],
          filters: [],
          aggregation_operations: [],
          column_order: [],
        },
      },
    },
  },
  {
    internalId: 'moving',
    externalName: 'moving',
    kind: 'objects',
    verboseName: 'In & utflytts-rapport',
    organizationFilterTargetKeys: ['objects.basespace.organization'],
    description:
      'Rapporten visar information om in- och utflyttningar för ett givet datum.',
    config: {
      report_filters: {
        company_ids: null,
        include_corporate_tenants: true,
        include_private_tenants: true,
        object_types: [
          'objects.apartment',
          'objects.industrialpremises',
          'objects.parkingspot',
          'objects.outdoorsection',
        ],
        period_end: DateTime.now().endOf('month').toFormat('yyyy-MM-dd'),
        period_start: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
        realestate_ids: null,
      },
      report_type: 'moving',
      sheet_configurations: {
        moving: {
          group_by: [],
          exclude_columns: [
            'organization.company.organization',
            'objects.realestate.organization',
            'objects.basestructure.organization',
            'objects.basespace.organization',
            'objects.basespace_revenue.organization',
            'objects.basespace_tags.organization',
            'contracts.basecontract.organization',
            'contracts.basecontract_revenue.organization',
            'accounts.tenant.organization',
            'objects.basespace_vacancy.organization',
          ],
          filters: [],
          aggregation_operations: [],
          column_order: [],
        },
      },
    },
  },
  {
    internalId: 'baserealestates',
    externalName: 'realestate',
    kind: 'base',
    verboseName: 'Fastigheter',
    organizationFilterTargetKeys: ['objects.realestate.organization'],
    description: 'Rapporten visar information om fastigheter.',
    config: {
      report_type: 'realestate',
      report_filters: {
        for_date: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
      },
      sheet_configurations: {
        realestate: {
          group_by: [],
          exclude_columns: [
            'objects.realestate.organization',
            'objects.realestate_calculated.organization',
          ],
          filters: [],
          aggregation_operations: [],
          column_order: [],
        },
      },
    },
  },
];
